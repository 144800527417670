import React from 'react';
import data from '../utils/data.json';
import Header from '../components/Header';
import Section from '../components/Section';
import Knowledge from '../components/Knowledge';
import Experience from '../components/Experience';
import Projects from '../components/Projects';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import { AboutPartial } from '../components/About';

const Home = () => {
  return (
    <>
    <Header data={data.header} socials={data.socials} />

    <Section id="knowledge" title="01" watermark="Knowledge">
      <Knowledge data={data.knowledge} />
    </Section>

    <Section id="experience" title="02" watermark="Experience">
      <Experience data={data.experience} socials={data.socials} />
    </Section>

    <Section id="portfolio" title="03" watermark="Portfolio">
      <Projects data={data.projects} />
    </Section>

    <Section id="about" title="About">
      <AboutPartial />
    </Section>

    <Section id="contact" title="Say hello &#x1f44b;">
      <Contact data={data.contact} />
    </Section>

    <Footer />
    </>
  )
};

export default Home;
