import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import './styles/App.scss';
import data from './utils/data.json';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import About from './pages/About';

function App() {
  return (
    <div>
      <Router>
        <NavBar data={data.navigators} />

        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          <Route component={Home} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
