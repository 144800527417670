import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import images from '../../utils/images';

const Projects = ({ data }) => {
  return (
    <div className="projects container-fluid">
      { data.slice(0,3).map(item => (
        <div key={item.id} className={`project row ${item.date === 'Coming Soon' ? 'coming-soon' : ''}`}>
          <div className="col-md-6 image">
            <img src={images[item.images[0]]} alt="" />
          </div>

          <div className="col-md-6 content">
            <p className="category">{ item.category }</p>
            <p className="project-title">{ item.title }</p>

            <div className="summary ml-3">
              <p>{ item.summary }</p>
              <div className="tags">
                { item.tags.map(tag => (
                  <span key={tag} className="pill">
                    { tag }
                  </span>
                )) }
              </div>
              <p className="date">{ item.date }</p>

              { item.links.length > 0 && (
                <div className="links mb-2">
                  { item.links.map(link => (
                    <a
                      key={link.id}
                      className="mr-2"
                      href={link.link}
                      target="_blank"
                      rel="noopener noreferrer">
                      { link.title }
                    </a>
                  )) }
                </div>
              )}
            </div>
          </div>
        </div>
      )) }

      {/* <div className="see-more">
        <div className="hr" />
        <a id={`ga-link-see-more-portfolio`} href="#">SEE MORE</a>
      </div> */}
    </div>
  )
};

Projects.propTypes = {
  data: PropTypes.array.isRequired,
}

export default Projects;
