import React, { useState } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import images from '../../utils/images';
import Modal from '../Modal';

const Experience = ({ data, socials }) => {
  const [currentCert, setCurrentCert] = useState({});
  const hm = data['happy-money'];
  const andela = data.andela;

  return (
    <div className="experience container-fluid">
      <div className="happy-money row" style={{backgroundImage: `url(${images.hmBg})`}}>
        <div className="col-md-6 text-center company">
          <a id={`ga-link-HappyMoney`} href={hm.url} target="_blank" rel="noopener noreferrer">
            <img src={images.hmLogo} alt="Happy Money" />
          </a>

          <p>{ hm.location }</p>
          { hm.summary.map(s => <p key={s}>{s}</p>) }
        </div>

        <div className="col-md-6 order-md-first">
          { hm.roles.map(role => (
            <div key={role.id} className="role">
              <p className="date">{ role.date }</p>
              <p className="title"><mark>{ role.title }</mark></p>
              <p>{ role.company }</p>
            </div>
          )) }
        </div>
      </div>

      <div className="andela row" style={{backgroundImage: `url(${images.worldMapBg})`}}>
        <div className="col-md-6 text-center company">
          <a id={`ga-link-Andela`} href={andela.url} target="_blank" rel="noopener noreferrer">
            <img src={images.andelaLogo} alt="Andela" width="120px" />
          </a>

          <p>{ andela.location }</p>
          { andela.summary.map(s => <p key={s}>{s}</p>) }

          <div className="certificates">
            { andela.certificates.map(certificate => (
              <div key={certificate.id} className="cert-image">
                <img
                  id={`ga-certificate-${certificate.name}`}
                  src={images[certificate.image]}
                  alt={certificate.name}
                  onClick={() => setCurrentCert(certificate)}
                  data-toggle="modal"
                  data-target="#andelaCertificateModal"
                />
              </div>
            )) }
          </div>

          <Modal id="andelaCertificateModal">
            <div className="certificate-modal">
              <div className="image">
                <img src={images[currentCert.image]} alt={currentCert.name} />
              </div>

              { currentCert.url ? (
                <div className="url">
                  <a id={`ga-link-see-${currentCert.name}`} href={currentCert.url} target="_blank" rel="noopener noreferrer">
                    See Credential
                  </a>
                </div>
              ) : null }
            </div>
          </Modal>
        </div>

        <div className="col-md-6 order-md-first">
          { andela.roles.map(role => (
            <div key={role.id} className="role">
              <p className="date">{ role.date }</p>
              <p className="title">{ role.title }</p>
              <p>{ role.company }</p>
            </div>
          )) }
        </div>
      </div>

      <div className="linked-in">
        <div className="hr" />
        <a id={`ga-link-LINKEDIN`} href={socials['linkedin-square']} target="_blank" rel="noopener noreferrer">LINKEDIN</a>
      </div>
    </div>
  )
};

Experience.propTypes = {
  data: PropTypes.object.isRequired,
  socials: PropTypes.object,
}

export default Experience;
