import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import Icon from 'react-web-vector-icons';

const SocialLinks = ({ data }) => {
  return (
    <div className="social-links">
      { Object.keys(data).map(key => (
        <a id={`ga-link-${key}`} href={data[key]} target="_blank" key={key} className="mr-3" rel="noopener noreferrer">
          <Icon
            font="FontAwesome"
            name={key}
            size={30}
            color="#868e96"
          />
        </a>
      )) }
    </div>
  )
};

SocialLinks.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SocialLinks;
