import fiyinPortrait from '../assets/images/fiyin-portrait.jpeg';
import fiyinGlasses from '../assets/images/fiyin-glasses.jpeg';
import headerBg from '../assets/images/header-bg.png';
import hmLogo from '../assets/images/happy-money-logo.svg';
import hmBg from '../assets/images/hm-desktop-wallpaper.png';
import andelaLogo from '../assets/images/andela-logo.png';
import worldMapBg from '../assets/images/world_map-1024x640.png';

import tedxyabaWeb from '../assets/images/projects/tedxyaba-web.png';
import myRhemaApp1 from '../assets/images/projects/rhema-app-iphone-table.jpg';
import myRhemaApp2 from '../assets/images/projects/rhema-app-iphone-x.png';
import playTribe1 from '../assets/images/projects/play-tribe-iphone.jpg';

import UdacityReact from '../assets/images/certificates/React-Nanodegree-Certificate.jpg';
import AndelaCompletion from '../assets/images/certificates/Andela-Certificate-Of-Completion.jpg';
import AndelaInterviewer from '../assets/images/certificates/Andela-Interviewer-certificate.jpg';

export default {
  fiyinPortrait,
  fiyinGlasses,
  headerBg,
  hmLogo,
  andelaLogo,
  hmBg,
  worldMapBg,
  tedxyabaWeb,
  myRhemaApp1,
  myRhemaApp2,
  playTribe1,
  UdacityReact,
  AndelaCompletion,
  AndelaInterviewer,
}
