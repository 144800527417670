import React, { useState } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import images from '../../utils/images';

const Knowledge = ({ data }) => {
  const [openCert, setOpenCert] = useState({});

  return (
    <div className="container-fluid">
      <div className="summary row">
        { Object.keys(data.summary).map(key => (
          <div key={key} className="col-md-6 offset-md-3 item">
            <p className="text">{ data.summary[key] }</p>
          </div>
        )) }
      </div>

      <div className="skills row">
        <div className="col-md-6 offset-md-3">
          <p className="skills-title">Skills</p>

          <div className="pills">
            { data.pills.map(pill => (
              <span key={pill} className="pill">
                { pill }
              </span>
            )) }
          </div>

          { data.skills.map(skill => (
            <div key={skill.text} className="item">
              <p className="text">
                { skill.text } :: { skill.level }% { skill.certification && <span id={`ga-certificate-${skill.text}`} onClick={() => setOpenCert({img: skill.certification, url: skill.url})} data-toggle="modal" data-target="#viewCertificateModal">view certificate</span> }
              </p>
              <div className="percent-bar" style={{width: `${skill.level}%`}} />
            </div>
          )) }
        </div>
      </div>

      <Modal id="viewCertificateModal">
        <div className="certificate-modal">
          <div className="image">
            <img src={images[openCert.img]} alt={openCert.img} />
          </div>

          { openCert.url ? (
            <div className="url">
              <a id={`ga-link-see-openCert`} href={openCert.url} target="_blank" rel="noopener noreferrer">
                See Credential
              </a>
            </div>
          ) : null }
        </div>
      </Modal>
    </div>
  )
};

Knowledge.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Knowledge;
