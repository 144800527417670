import React from 'react';
import './styles.scss';

const Footer = () => {
  return (
    <footer className="f-footer">
      <p>&copy; {new Date().getFullYear()} <span className="name">Fiyin Adebayo</span>. All rights reserved. Hand-crafted with care. &#x1F920;</p>
    </footer>
  )
};

export default Footer;
