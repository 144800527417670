import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import Icon from 'react-web-vector-icons';
import Menu from '../Menu';

const NavBar = ({ data }) => {
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  const openMenu = () => {
    document.getElementById("menu").style.height = '100%';
  }

  const closeMenu = () => {
    document.getElementById("menu").style.height = '0';
  }

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    window.open('/', '_self')
  }

  // https://css-tricks.com/sticky-smooth-active-nav/
  // https://stackoverflow.com/questions/59198952/using-document-queryselector-in-react-should-i-use-refs-instead-how

  return (
    <>
    <Menu data={data} onClose={closeMenu} />

    <nav className="navbar">
      <div className="logo">
        <Link id={`ga-nav-goto-HOME`} to="/">F A _</Link>
      </div>

      <div className="nav-items--desktop">
        <div className={`nav-item ${isHome ? 'active' : ''}`} onClick={scrollToTop}>
          <Icon font="AntDesign" name="home" size={25} color="#868e96" />
        </div>

        <div className="nav-item" onClick={openMenu}>
          <Icon font="Feather" name="menu" size={25} color="#868e96" />
        </div>
      </div>
    </nav>
    </>
  )
};

NavBar.propTypes = {
  data: PropTypes.array.isRequired,
}

export default NavBar;
