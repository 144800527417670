import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

const Contact = ({ data }) => {
  return (
    <div className="contact">
      <p className="tagline">{ data.tagline }</p>
      <p className="email"><a id={`ga-link-${data.email}`} href={`mailto:${data.email}`}>{ data.email }</a></p>
    </div>
  )
};

Contact.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Contact;
