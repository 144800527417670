import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import PropTypes from 'prop-types';
import images from '../../utils/images';
import SocialLinks from '../SocialLinks';

export const AboutFull = ({ data, socials }) => {
  return (
    <div className="about-full container-fluid">
      <div className="row">
        <div className="col-md-6 details">
          <p className="intro">{ data.intro }</p>
          <p className="multiline-text">{ data.details }</p>

          <div className="text-center my-4 socials">
            <SocialLinks data={socials} />
          </div>
        </div>

        <div className="col-md-6 a-image">
          <img src={images.fiyinPortrait} alt="Fiyin Adebayo" />
        </div>
      </div>
    </div>
  )
}

AboutFull.propTypes = {
  data: PropTypes.object.isRequired,
  socials: PropTypes.object,
}

export const Recommendations = ({ data }) => {
  return (
    <div className="recommendations container-fluid">
      <div className="row justify-content-center">
        <div className="col-md-6 text-center">
          { data.slice(0).map(item => (
            <div key={item.id}>
              <p className="multiline-text">{ item.details }</p>

              <div className="mt-4">
                <p className="name">
                  <a id={`ga-link-${item.name}`} href={item.linkedin} target="_blank" rel="noopener noreferrer">
                    { item.name }
                  </a>
                </p>
                <p className="role">{ item.currentRole }</p>
                { item.previousRole && <p className="role">(former { item.previousRole })</p> }
              </div>
            </div>
          )) }
        </div>
      </div>
    </div>
  )
}

Recommendations.propTypes = {
  data: PropTypes.array.isRequired,
}

export const AboutPartial = () => {
  return (
    <div className="about-partial container-fluid">
      <div className="row justify-content-center">
        <div className="col-md-5 text-center">
          <p>You can usually find me playing around with Sketch while fiddling with the most incredible design inspiration yet, programming my way through the week&hellip;</p>

          <Link id={`ga-link-About-READ-MORE`} to="/about" className="read-more">READ MORE</Link>
        </div>
      </div>
    </div>
  )
}
