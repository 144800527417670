import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

const Section = ({ id, title, watermark, children, style }) => {
  return (
    <section id={id} className="f-section" style={style}>
      <p className="title">{ title }</p>
      { watermark && <p className="watermark">{ watermark }</p> }
      { children }
    </section>
  )
};

Section.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  watermark: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.element,
}

Section.defaultProps = {
  style: {}
}

export default Section;
