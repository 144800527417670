import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from 'react-web-vector-icons';

const Menu = ({ data, onClose }) => {
  return (
    <div id="menu" className="menu">
      <div className="close-icon" onClick={onClose}>
        <Icon font="AntDesign" name="close" size={30} color="white" />
      </div>

      { data.slice(1).map(item => (
        <div className="nav-item" key={item.id} onClick={onClose}>
          { item.path.indexOf('#') >= 0 ? (
            <a id={`ga-nav-${item.name}`} href={`#${item.name.toLowerCase()}`}>{ item.name }</a>
          ) : (
            <Link id={`ga-nav-${item.name}`} to={item.path}>{ item.name }</Link>
          ) }
        </div>
      )) }
    </div>
  )
};

Menu.propTypes = {
  data: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Menu;
