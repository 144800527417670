import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import Images from '../../utils/images';
import SocialLinks from '../SocialLinks';

const Header = ({ data, socials }) => {
  return (
    <>
    <header className="container-fluid main-header">
      <div className="row">
        <div className="col-sm-6 content">
          <div>
            <div className="my-name">
              <p>Hi, I'm { data.firstName }.</p>
            </div>

            <div className="roles">
              {data.jobTitles.join(', ')}
            </div>

            <SocialLinks data={socials} />
          </div>
        </div>

        <div className="col-sm-6 h-image">
          <img src={Images.fiyinGlasses} alt="" />
        </div>
      </div>
    </header>

    <div className="container-fluid">
      <div className="bio row justify-content-center">
        <div className="col-10 col-sm-7 text multiline-text">
          { data.bio }
        </div>
      </div>
    </div>
    </>
  )
};

Header.propTypes = {
  data: PropTypes.object.isRequired,
  socials: PropTypes.object,
}

export default Header;
