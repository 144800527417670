import React from 'react';
import data from '../utils/data.json';
import { AboutFull, Recommendations } from '../components/About';
import Section from '../components/Section';
import Footer from '../components/Footer';
import Contact from '../components/Contact';

const About = () => {
  return (
    <div className="pt-3">
      <Section id="about" title="A little bit about me&hellip;">
        <AboutFull data={data.about} socials={data.socials} />
      </Section>

      <Section id="recommendations" title="Recommendations">
        <Recommendations data={data.recommendations} />
      </Section>

      <Section id="contact" title="Say hello &#x1f44b;">
        <Contact data={data.contact} />
      </Section>

      <Footer />
    </div>
  )
};

export default About;
