import React from 'react';
import PropTypes from 'prop-types';
// import Icon from 'react-web-vector-icons';

const Modal = ({ id, children }) => {
  return (
    <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby={`${id}Title`} aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          
          <div className="modal-body">
            { children }
          </div>

          <div className="modal-controls">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              {/* <Icon
                font="AntDesign"
                name="close"
                color="#101010"
                size={30}
              /> */}
              <span>CLOSE</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
}

export default Modal;
